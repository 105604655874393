@import "../../../scss/general_styling";

.TokenArrow {
  display: flex;

  .Arrow {
    margin: 0 1em;
  }

  .IconToken {
    width: 40px;
  }
}
