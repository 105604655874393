@import "../../../scss/general_styling";

.Subtitle {
  display: flex;

  img {
    width: 20px;
    margin-right: 0.5em;
  }
  p {
    @include primary-text($body, $grey-3, 600);
  }
}
