@import "../../../scss/general_styling";

.ProgressBar {
  .ProgressBarTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5em;
    p {
      @include primary-text($label, $grey-3, 400);
    }
  }
  .bg-theme {
    background: $gradient-theme-3 !important;
  }
}
