@import "../../../scss/general_styling";

.TokenAmount {
  padding: 1em;
  margin: 1em 0;
  background: $gradient-theme-2;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .AmountContent {
    p {
      @include primary-text($body, $white, 400);
      margin-bottom: 0.5em;
    }
    .AmountNumber {
      display: flex;
      align-items: center;

      h3 {
        @include primary-text($title, $white, 600);
        margin-left: 1em;
      }
    }
  }
}
