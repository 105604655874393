@import "../../../scss/general_styling";

.InfoLabel {
  p {
    @include primary-text($label, $grey-3, 400);
    strong {
      font-weight: 600;
    }
  }
  &.ThemeRight {
    text-align: right;
  }
  &.ThemeLeft {
    text-align: left;
  }
}
