@import "../../../scss/general_styling";

.SeparationLine {
  display: flex;
  width: 100%;
  justify-content: space-between;

  img {
    width: 30px;
  }

  .LineOne {
    border: none;
    border-top: 2px solid $secondary-color;
    width: 42%;
  }
  .LineTwo {
    border: none;
    border-top: 2px solid $primary-color;
    width: 42%;
  }
}
