@import "../../../scss/general_styling";

.TokenWrap {
  background: $white;
  border-radius: 6px;
  width: 100%;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;

  .InputContainer {
    width: 70%;
    display: flex;
    align-items: center;
    .btnMax {
      @include primary-text($body, $primary-color, 600);
      border-bottom: 2px solid $primary-color;
      cursor: pointer;
      margin: 0 1em;
    }
  }
  .SelectorContainer {
    width: 30%;
    display: flex;
    align-items: center;
  }
}
