@import "../../../scss/general_styling";

.NetworkItem {
  margin-bottom: 1em;
  p {
    @include primary-text($label, $white, 400);
  }
  .ItemContent {
    margin-top: 0.5em;
    display: flex;
    align-items: center;
    width: 200px;

    .IconNetwork {
      width: 40px;
      margin-right: 1em;
    }

    h3 {
      @include primary-text($h3, $white, 600);
      margin-bottom: 0;
    }
  }
}
