@import "../../../scss/general_styling";

.NetworkConfirm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5em 0;
  padding-left: 10px;

  .TimerText {
    @include primary-text($h4, $white, 600);
    margin-bottom: -20px;
  }

  .ProgressBar {
    width: 55%;
  }

  .NetworkItem {
    transform: scale(0.9);
    margin-left: -15px;
    margin-bottom: -15px;
  }
}
