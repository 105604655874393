@import "../../../scss/general_styling";

.TransactionDetail {

  .Wrapper1 {
    display: grid;
    grid-template-columns: 200px 120px 100px 100px 80px;

    .box1 {
      @include primary-text($label, $grey-3, 400);
      padding: 10px;
      background-color: transparent;
    }
  }

  .Wrapper2 {
    display: grid;
    grid-template-columns: 200px 120px 100px 100px 80px;
    background: $gradient-theme-2;
    border-radius: 6px;

    .box2 {
      color: #fff;
      padding: 20px 10px 10px 10px;
  
      h3 {
        @include primary-text($h4, $white, 400);
      }
    }

    .TokenArrow {
      transform: scale(0.7);
      margin-left: -15px;
      margin-top: -10px;
    }

    .iconLink{
      margin-top: -5px;
    }
  }
}
