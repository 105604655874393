@import "../../../scss/general_styling";

.WalletButton {
  background-color: $background;
  border-radius: 6px;
  width: 80%;
  display: flex;
  align-items: center;
  padding: 1em;
  justify-content: space-between;
  box-shadow: $shadow;
  cursor: pointer;

  .ButtonContent {
    display: flex;
    align-items: center;

    .IconWallet {
      width: 60px;
    }

    .TextContent {
      margin-left: 1em;
      h3 {
        @include primary-text($h2, $white, 600);
      }
      p {
        @include primary-text($body, $white, 400);
      }
    }
  }
}

