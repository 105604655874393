@import "../../../scss/general_styling";

.FeeItem {
  background: $gradient-theme-2;
  display: flex;
  width: 100%;
  border-radius: 6px;
  padding: 0.5em;

  img {
    width: 20px;
    margin-right: 0.5em;
  }
  p {
    @include primary-text($body, $white, 400);
    strong {
      font-weight: 600;
    }
  }
}
