@import "../../../scss/general_styling";

.PrincipalCard {
  background-color: transparent;

  .PrincipalContent {
    padding: 10px !important;
    border: 2px solid $white;

    background: linear-gradient(
      241.11deg,
      rgba(255, 162, 61, 0.2) 0%,
      rgba(162, 187, 255, 0.1) 100%
    );

    border-radius: 10px;
  }
}

.StepContent {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Step2Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .LabelsContainer {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 1.5em;
    align-items: center;

    &.Two {
      padding: 0.5em 1.5em 0.5em 0;
    }
  }

  .Separator {
    border: none;
    border-top: 2px solid $background;
  }
}

.TitleContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;

  img {
    margin-bottom: 1em;
  }

  .StepTitle {
    font-size: 24px;
    color: $white;
    text-align: center;
  }

  .Subtitle {
    font-size: 14px;
    color: $white;
    text-align: center;
    .Link {
      color: $primary-color;
      font-weight: 700;
    }
  }
}

.card {
  background-color: transparent !important;
}
